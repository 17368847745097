"use client";

import { Table } from "@tanstack/react-table";
import { ContextMenu } from "../ContextMenu";
import { CheckboxInput } from "../Inputs/CheckboxInput";

export type DataTableViewOptionsProps<TData> = {
  table: Table<TData>;
};

export function DataTableViewOptions<TData>({
  table,
}: DataTableViewOptionsProps<TData>) {
  const columnsWeCanHide = table
    .getAllColumns()
    .filter(
      (column) =>
        typeof column.accessorFn !== "undefined" && column.getCanHide()
    );

  if (columnsWeCanHide.length === 0) return null;

  return (
    <ContextMenu
      icon="sliders-horizontal"
      buttonLabel="View"
      position="bottom-end"
      iconStyle="fill-gray-900"
      buttonStyles="flex flex-row-reverse gap-2 items-center rounded-full py-2 px-3 font-medium border text-sm bg-white hover:bg-gray-100 rounded-lg transition-colors duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
      customContent={() => (
        <ul className="-mt-[1px] w-64 rounded-lg border border-b border-gray-200 bg-white py-1 focus-visible:outline-none">
          <li className="border-b py-2 pl-3 text-sm font-semibold text-gray-500">
            Toggle columns
          </li>
          {columnsWeCanHide.map((column) => {
            return (
              <li className="mx-1 rounded-md px-2 py-1" key={column.id}>
                <CheckboxInput
                  id={column.id}
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => {
                    column.toggleVisibility(!!value);
                  }}
                  label={column.id}
                />
              </li>
            );
          })}
        </ul>
      )}
    />
  );
}
