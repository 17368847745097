import React, { useEffect, useState } from "react";
import {
  Button,
  Icon,
  Modal,
  SelectInput,
  SelectionBox,
  TextInput,
  Tooltip,
  useToast,
} from "..";
import { createContact, updateContact } from "../../../../apis/contacts/api";
import { useAuth } from "../../../../state/AuthContexts";
import { validateAndSetErrors } from "../../schemas";
import { contactSchema } from "../../schemas/userSchema";

type PropsT = {
  open: boolean;
  setOpen: (value: boolean) => void;
  contact?: ContactType;
  setContact?: (value: ContactType) => void;
};

export default function ContactModal(props: PropsT) {
  const { contact, open, setOpen, setContact } = props;
  const { user } = useAuth();
  const { toast } = useToast();

  const [savingContact, setSavingContact] = useState(false);
  const [errors, setErrors] = useState<ErrorT>();
  const [role, setRole] = useState("customer");
  const paymentTermOptions = [
    { value: "15", label: "15" },
    { value: "30", label: "30" },
    { value: "45", label: "45" },
    { value: "60", label: "60" },
    { value: "90", label: "90" },
    { value: "100", label: "100" },
    { value: "120", label: "120" },
  ];

  const [contactValues, setContactValues] = useState({
    name: contact?.company_name || "",
    email: contact?.email || "",
    phone: contact?.phone || "",
    firstName: contact?.first_name || "",
    lastName: contact?.last_name || "",
    payment_terms: contact?.payment_terms,
  });

  useEffect(() => {
    if (contact?.id) {
      setContactValues({
        name: contact?.company_name || "",
        email: contact?.email || "",
        phone: contact?.phone || "",
        firstName: contact?.first_name || "",
        lastName: contact?.last_name || "",
        payment_terms: contact?.payment_terms,
      });
      setRole(contact?.role || "customer");
    }
  }, [contact]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSavingContact(true);
    if (user?.token) {
      const hasError = await validateAndSetErrors(
        contactSchema,
        contactValues,
        setErrors
      );

      if (hasError) {
        setSavingContact(false);
        return;
      }

      if (contact?.id) {
        updateContact({
          contact: {
            id: contact?.id,
            first_name: contactValues.firstName,
            last_name: contactValues.lastName,
            email: contactValues.email,
            phone: contactValues.phone,
            company_name: contactValues.name,
            role: role,
            payment_terms: contactValues.payment_terms,
          },
        }).then((response) => {
          if (response.success) {
            toast({
              variant: "success",
              title: "Contact updated successfully!",
            });
          } else {
            toast({
              variant: "error",
              title: response.message,
            });
          }

          onClose();
        });
      } else {
        createContact({
          inviter_id: user?.id,
          company_name: contactValues.name,
          email: contactValues.email.trim(),
          role: role,
          first_name: contactValues.firstName,
          last_name: contactValues.lastName,
          phone: contactValues.phone,
          payment_terms: contactValues.payment_terms,
        }).then((response) => {
          if (response.success) {
            setContact?.(response.contact);
            toast({
              variant: "success",
              title: "Success!",
              description: "Contact created successfully!",
            });
          } else {
            toast({
              variant: "error",
              title: response.message,
            });
          }
          onClose();
        });
      }
    }
  };

  const onClose = () => {
    setErrors({});
    setOpen(false);
    setContactValues({
      name: "",
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      payment_terms: "",
    });
    setSavingContact(false);
  };

  return (
    <Modal
      open={open}
      setOpen={onClose}
      title={contact?.id ? "Edit contact info" : "Add a new contact"}
    >
      <div
        className="w-[90vw] px-5 py-4 md:w-[672px] md:px-6 md:py-5"
        data-testid="contact-modal"
      >
        <form className="" onSubmit={onSubmit}>
          <div className="space-y-3 md:space-y-5">
            <TextInput
              label="Company name"
              wide
              id="company-name-contact-modal"
              value={contactValues.name}
              error={errors?.name}
              onChange={(e) => {
                setContactValues({ ...contactValues, name: e.target.value });
                setErrors({ ...errors, name: "" });
              }}
            />
            <div className="flex flex-col space-y-3 md:flex-row md:space-x-4 md:space-y-0">
              <TextInput
                label="Email"
                value={contactValues.email}
                error={errors?.email}
                onChange={(e) => {
                  setContactValues({ ...contactValues, email: e.target.value });
                  setErrors({ ...errors, email: "" });
                }}
              />
              <TextInput
                label="Phone"
                value={contactValues.phone}
                showRequired
                onChange={(e) =>
                  setContactValues({ ...contactValues, phone: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col space-y-3 md:flex-row md:space-x-4 md:space-y-0">
              <TextInput
                label="First name"
                value={contactValues.firstName}
                showRequired
                onChange={(e) =>
                  setContactValues({
                    ...contactValues,
                    firstName: e.target.value,
                  })
                }
              />
              <TextInput
                label="Last name"
                value={contactValues.lastName}
                showRequired
                onChange={(e) =>
                  setContactValues({
                    ...contactValues,
                    lastName: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="mt-5 flex flex-row items-center gap-x-2">
            <span className="whitespace-nowrap text-sm font-medium text-gray-900">
              Default payment terms
            </span>
            <Tooltip
              subtitle="Leave this blank if you don't want to set contact-level payment terms"
              position="top"
            >
              <Icon icon="question" size="18" />
            </Tooltip>
            <div className="w-20">
              <SelectInput
                placeholder="---"
                wide
                options={[{ value: "", label: "---" }, ...paymentTermOptions]}
                value={paymentTermOptions.find(
                  (o) => o.value === contactValues.payment_terms?.toString()
                )}
                onChange={(e) =>
                  setContactValues({
                    ...contactValues,
                    payment_terms: e.value.toString(),
                  })
                }
                buttonClassName="h-8 !py-0"
              />
            </div>

            <span className="text-sm font-medium text-gray-900">days</span>
          </div>

          <div className="mt-5">
            <div className="text-sm font-medium text-gray-900">
              Assign contact type
            </div>
            <div className="mt-2 flex flex-col gap-4 sm:flex-row">
              <SelectionBox
                id="vendorSelect"
                value={role === "vendor"}
                title="Supplier"
                subtitle="(Seller)"
                wide
                icon="storefront"
                onClick={() => setRole("vendor")}
              />
              <SelectionBox
                id="customerSelect"
                title="Customer"
                subtitle="(Buyer)"
                wide
                icon="user"
                value={role === "customer"}
                onClick={() => setRole("customer")}
              />
            </div>
          </div>
          <div className="mt-4 flex flex-row items-end justify-end space-x-3.5 md:mt-8">
            <Button
              label="Cancel"
              color="white"
              outline
              onClick={() => onClose()}
            />
            <Button label="Save" color="dark" loading={savingContact} submit />
          </div>
        </form>
      </div>
    </Modal>
  );
}
