import clsx from "clsx";
import React, { MouseEventHandler } from "react";
import { Icon } from "../Icon";

export type PropsT = {
  "data-testid"?: string;
  onClick?: MouseEventHandler;
  submit?: boolean;
  disabled?: boolean;
  loading?: boolean;
  wide?: boolean;
  label?: string;
  color?:
    | "ghost"
    | "primary"
    | "orange"
    | "green"
    | "alternative"
    | "transparent"
    | "dark"
    | "white"
    | "gradient"
    | "shadow";
  outline?: boolean;
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  customIconSize?: string;
  customIconStyles?: string;
  icon?: string;
  iconLeft?: boolean;
  iconRight?: boolean;
  autoFocus?: boolean;
  hideLabelOnMobile?: boolean;
  align?: "left" | "center";
  className?: string;
};

export const Button = (props: PropsT): JSX.Element => {
  const {
    "data-testid": dataTestId,
    onClick,
    submit = false,
    loading = false,
    disabled = false,
    wide = false,
    label = "",
    outline = false,
    color,
    size,
    customIconSize,
    customIconStyles,
    icon = "",
    iconLeft = false,
    iconRight = false,
    autoFocus = false,
    hideLabelOnMobile,
    align = "center",
    className = "",
  } = props;

  let buttonSize = "";
  let textColor = "";
  let textStyle = "";
  let backgroundColor = "";
  let borderColor = "";
  let iconColor = "";
  let iconSize = "";
  let loaderSize = "";

  switch (size) {
    case "xs":
      buttonSize = label ? "h-[34px] px-[14px]" : "h-[20px] w-[20px]";
      textStyle = "font-medium text-xs";
      iconSize = "12";
      loaderSize = "h-3 w-3";
      break;
    case "sm":
      buttonSize = label ? "h-[37px] px-4" : "h-[28px] w-[28px]";
      textStyle = "font-medium text-sm";
      iconSize = "12";
      loaderSize = "h-4 w-4";
      break;
    case "base":
      buttonSize = hideLabelOnMobile
        ? label
          ? "h-[40px] w-[40px] sm:h-[41px] sm:w-fit sm:px-6"
          : ""
        : label
          ? "h-[41px] px-6"
          : "h-[34px] w-[34px]";
      textStyle = "font-medium text-sm";
      iconSize = "14";
      loaderSize = "h-4 w-4";
      break;
    case "lg":
      buttonSize = label ? "h-12 px-6" : "h-[40px] w-[40px]";
      textStyle = "font-medium text-base";
      iconSize = "16";
      loaderSize = "h-5 w-5";
      break;
    case "xl":
      buttonSize = label ? "h-[52px] px-[28px]" : "h-11 w-11";
      textStyle = "font-medium text-base";
      iconSize = "18";
      loaderSize = "h-5 w-5";
      break;
    default: // default to base
      buttonSize = hideLabelOnMobile
        ? label
          ? "h-[40px] w-[40px] sm:h-[41px] sm:w-fit sm:px-6"
          : ""
        : label
          ? "h-[41px] px-6"
          : "h-[34px] w-[34px]";
      textStyle = "font-medium text-sm";
      iconSize = "14";
      loaderSize = "h-4 w-4";
  }

  switch (color) {
    case "ghost":
      textColor = "text-gray-900";
      backgroundColor =
        "bg-transparent hover:bg-gray-100 focus-visible:outline-gray-900";
      borderColor = "border border-gray-300 rounded-lg";
      iconColor = "fill-gray-900";
      break;
    case "primary":
      textColor = "text-white";
      backgroundColor =
        "bg-primary-500 hover:bg-primary-700 focus-visible:outline-primary-700";
      borderColor = "border border-transparent rounded-full";
      iconColor = "fill-white";
      break;
    case "orange":
      textColor = "text-white";
      backgroundColor =
        "bg-orange-600 hover:bg-orange-700 focus-visible:outline-orange-700";
      borderColor = "border border-transparent rounded-full";
      iconColor = "fill-white";
      break;
    case "green":
      textColor = "text-white";
      backgroundColor =
        "bg-green-600 hover:bg-green-700 focus-visible:outline-green-700";
      borderColor = "border border-transparent rounded-full";
      iconColor = "fill-white";
      break;
    case "alternative":
      textColor = "text-gray-900";
      backgroundColor = "bg-white hover:shadow focus-visible:outline-gray-900";
      borderColor = "border border-gray-300 hover:border-gray-900 rounded-lg";
      iconColor = "fill-gray-900";
      break;
    case "transparent":
      textColor = "text-gray-900";
      backgroundColor =
        "bg-transparent hover:bg-gray-100 hover:bg-gray-100 focus-visible:outline-gray-500";
      if (outline) {
        borderColor =
          "border border-gray-200 focus-visible:outline-gray-900 rounded-full";
      } else {
        borderColor = "border border-transparent rounded-full";
      }
      iconColor = "fill-gray-500 group-hover:fill-gray-900";
      break;
    case "gradient":
      textColor = "text-white";
      backgroundColor =
        "bg-gradient-1 hover:bg-gradient-2 focus-visible:outline-primary-600";
      borderColor = "border-transparent rounded-full";
      iconColor = "fill-white";
      break;
    case "white":
      if (outline) {
        textColor = "text-gray-900";
        backgroundColor =
          "bg-white hover:bg-gray-100 focus-visible:outline-gray-200";
        borderColor = "border border-gray-200 rounded-full";
        iconColor = "fill-gray-900";
      } else {
        textColor = "text-gray-900";
        backgroundColor =
          "bg-white hover:bg-gray-100 focus-visible:outline-gray-900";
        borderColor = "border border-transparent rounded-full";
        iconColor = "fill-gray-900";
      }
      break;
    case "dark":
      if (outline) {
        textColor = "text-gray-900";
        backgroundColor =
          "bg-white hover:bg-gray-100 focus-visible:outline-gray-900";
        borderColor = "border border-gray-900 rounded-full";
        iconColor = "fill-gray-900";
      } else {
        textColor = "text-white";
        backgroundColor =
          "bg-gray-800 hover:bg-gray-700 focus-visible:outline-gray-900";
        borderColor = "border border-transparent rounded-full";
        iconColor = "fill-white";
      }
      break;
    case "shadow":
      textColor = "text-gray-900";
      backgroundColor =
        "bg-white hover:bg-gray-100 focus-visible:outline-gray-900";
      borderColor = "border border-transparent rounded-lg shadow-sm";
      iconColor = "fill-gray-900";
      break;
    default: // defaults to dark
      if (outline) {
        textColor = "text-gray-900";
        backgroundColor =
          "bg-white hover:bg-gray-100 focus-visible:outline-gray-900";
        borderColor = "border border-gray-900 rounded-full";
        iconColor = "fill-gray-900";
      } else {
        textColor = "text-white";
        backgroundColor =
          "bg-gray-800 hover:bg-gray-700 focus-visible:outline-gray-900";
        borderColor = "border border-transparent rounded-full";
        iconColor = "fill-white";
      }
  }

  return (
    <button
      className={clsx(
        "Button group relative inline-flex min-w-fit items-center whitespace-nowrap transition-colors duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none sm:flex-grow-0",
        {
          [backgroundColor]: backgroundColor,
          [buttonSize]: buttonSize,
          [borderColor]: borderColor,
          [className]: className,
          "!pointer-events-none !opacity-50": disabled,
          "pointer-events-none": loading,
          "w-full": wide,
          "justify-center": align === "center",
        }
      )}
      onClick={onClick}
      disabled={disabled || loading}
      data-testid={dataTestId || label || icon}
      type={submit ? "submit" : "button"}
      autoFocus={autoFocus}
    >
      <IncreaseTouchTarget>
        {loading && (
          <svg
            className={`absolute inset-x-0 mx-auto animate-spin ${textColor} ${loaderSize}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            data-testid="loader"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        )}

        {/* For left icon */}
        {icon && !iconRight && label && (
          <Icon
            icon={icon}
            iconStyle={`${customIconStyles || iconColor} ${hideLabelOnMobile ? "ml-0 sm:mr-2" : "mr-2"} ${loading ? "fill-transparent" : ""}`}
            size={customIconSize || iconSize}
          />
        )}

        {/* For centered icon */}
        {icon && !label && (
          <Icon
            icon={icon}
            iconStyle={`${customIconStyles || iconColor} ${loading ? "fill-transparent" : ""}`}
            size={customIconSize || iconSize}
          />
        )}

        {label && (
          <span
            className={`${loading ? "!text-transparent" : ""} ${hideLabelOnMobile ? "hidden sm:block" : ""} ${textColor} ${textStyle}`}
          >
            {label}
          </span>
        )}

        {/* For right icon */}
        {icon && !iconLeft && iconRight && label && (
          <Icon
            icon={icon}
            iconStyle={`${customIconStyles || iconColor} ${hideLabelOnMobile ? "ml-0 sm:ml-2" : "ml-2"} ${loading ? "fill-transparent" : ""}`}
            size={customIconSize || iconSize}
          />
        )}

        {hideLabelOnMobile && label && <span className="sr-only">{label}</span>}
        {icon && !label && <span className="sr-only">{icon}</span>}
      </IncreaseTouchTarget>
    </button>
  );
};

const IncreaseTouchTarget = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {children}
      <span
        className="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden"
        aria-hidden="true"
        data-testid="increase-touch-target"
      />
    </>
  );
};
