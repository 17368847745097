import { useCallback, useState } from "react";
import {
  syncReleaseFundsEntries,
  syncRepaymentEntries,
} from "../../../../apis/erp";
import { useAuth } from "../../../../state";
import { useToast } from "../../../shared/components";
import { getBuyerEPState, getBuyerRepaymentState } from "../helpers";

type PropsT = {
  invoice: InvoiceType;
  isBill: boolean;
  onSuccess?: () => void;
};

export const useJournalEntries = ({ invoice, isBill, onSuccess }: PropsT) => {
  const { company, selectedEntity } = useAuth();
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const isJEReady =
    invoice.early_pay && Boolean(company?.Connections?.[0]) && Boolean(isBill);

  const epProcessingState = getBuyerEPState(invoice, selectedEntity);

  const repaymentProcessingState = getBuyerRepaymentState(
    invoice,
    selectedEntity
  );

  const isEPEntriesReady = isJEReady && epProcessingState.state === "ready";

  const isRepaymentEntriesReady =
    isJEReady && repaymentProcessingState.state === "ready";

  const isEntriesProcessing =
    epProcessingState.state === "in-progress" ||
    repaymentProcessingState.state === "in-progress";

  const isWithPendingEntries =
    isEPEntriesReady || isRepaymentEntriesReady || isEntriesProcessing;

  const hidePushSupplierCredit =
    company?.Connections?.[0]?.connection_type !== "sage300";

  const releaseFundsEntries = useCallback(async () => {
    if (isLoading) return;

    const loader = toast({
      title: "Pushing entries...",
      loader: true,
    });

    setIsLoading(true);

    syncReleaseFundsEntries({
      id: invoice?.parent_id ? invoice?.parent_id : invoice?.id,
      type: "buyer",
    })
      .then(() => {
        loader.update({
          id: loader.id,
          variant: "success",
          title: "Entries pushed successfully!",
          loader: false,
        });
      })
      .catch(() => {
        loader.update({
          id: loader.id,
          variant: "error",
          title: "Error pushing entries",
          loader: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
        onSuccess?.();
      });
  }, [invoice?.id, invoice?.parent_id, onSuccess, toast, isLoading]);

  const repaymentEntries = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);

    const loader = toast({
      title: "Pushing entries...",
      loader: true,
    });

    await syncRepaymentEntries({
      id: invoice?.parent_id ? invoice?.parent_id : invoice?.id,
      type: "buyer",
      method: "repayment",
    })
      .then(() => {
        loader.update({
          id: loader.id,
          variant: "success",
          title: "Entries pushed successfully!",
          loader: false,
        });
      })
      .catch(() => {
        loader.update({
          id: loader.id,
          variant: "error",
          title: "Error pushing entries",
          loader: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
        onSuccess?.();
      });
  }, [invoice?.id, invoice?.parent_id, onSuccess, toast, isLoading]);

  const pushSupplierDiscount = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);

    const loader = toast({
      title: "Pushing supplier discount...",
      loader: true,
    });

    await syncRepaymentEntries({
      id: invoice?.parent_id ? invoice?.parent_id : invoice?.id,
      type: "buyer",
      method: "supplier-credit",
    });

    loader.update({
      id: loader.id,
      variant: "success",
      title: "Supplier discount pushed successfully!",
      loader: false,
    });

    setIsLoading(false);
    onSuccess?.();
  }, [invoice?.id, invoice?.parent_id, onSuccess, toast, isLoading]);

  return {
    isLoading,
    isJEReady,
    epProcessingState,
    repaymentProcessingState,
    isEPEntriesReady,
    isRepaymentEntriesReady,
    isWithPendingEntries,
    releaseFundsEntries,
    repaymentEntries,
    pushSupplierDiscount,
    hidePushSupplierCredit,
  };
};
