import { Table } from "@tanstack/react-table";
import { Button } from "../Button";

type DataTablePaginationProps<TData> = {
  table: Table<TData>;
  selectable?: boolean; //maybe dont need this?
};

export function DataTablePagination<TData>({
  table,
  selectable,
}: DataTablePaginationProps<TData>) {
  const currentPage = table.getState().pagination.pageIndex + 1;
  const totalRows = table.getRowCount();

  return (
    <div className="flex items-center justify-between">
      {selectable ? (
        <div className="flex-1 text-sm text-gray-500">
          {Object.keys(table.getState().rowSelection).length} of {totalRows}{" "}
          row(s) selected.
        </div>
      ) : (
        <div className="flex-1 text-sm text-gray-500">
          Showing{" "}
          {currentPage * table.getState().pagination.pageSize -
            table.getState().pagination.pageSize +
            1}
          -
          {currentPage * table.getState().pagination.pageSize >= totalRows
            ? totalRows
            : currentPage * table.getState().pagination.pageSize}
          {" of "}
          {totalRows}
        </div>
      )}
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex w-28 items-center justify-end text-sm font-medium">
          Page {currentPage} of {table.getPageCount()}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            icon="caret-double-left"
            size="sm"
            data-testid="firstPage"
            color="ghost"
            onClick={() => table.firstPage()}
            disabled={!table.getCanPreviousPage()}
            className="bg-white"
          />
          <Button
            icon="caret-left"
            size="sm"
            data-testid="previousPage"
            color="ghost"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="bg-white"
          />
          <Button
            icon="caret-right"
            size="sm"
            data-testid="nextPage"
            color="ghost"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="bg-white"
          />
          <Button
            icon="caret-double-right"
            size="sm"
            data-testid="lastPage"
            color="ghost"
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
            className="bg-white"
          />
        </div>
      </div>
    </div>
  );
}
